<template>
  <section class="page-other-transaction-add">
    <div data-testid="back-page" @click="handleBackButton">
      <page-header
        :back-to="{ name: 'other-transaction' }"
        title="Ubah Data Transaksi Lainnya"
        class="mb-32"
      />
    </div>
    <bg-grid>
      <bg-grid-item :col="12">
        <bg-field
          class="mb-16"
          label="Jenis Data Transaksi"
          description="Pengeluaran Properti"
        />

        <bg-divider />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-field label="Tipe Pengajuan Cash-Out">
          <bg-skeleton
            v-if="isLoading.page"
            width="100%"
            height="48px"
            data-testid="loader"
          />
          <bg-select
            v-else
            v-model="input.cash_out_id"
            placeholder="Pilih tipe pengajuan cash-out"
            data-testid="cash-out-id"
            :options="options.cash_out"
          />
        </bg-field>
        <bg-field label="Nama Properti">
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />

          <bg-search-bar
            v-else
            v-model="display.property"
            placeholder="Pilih properti"
            data-testid="property"
            :suggestions="options.propertyName"
            :loading="isLoading.propertyList"
            @input="onSearchInputProperty"
            @click-suggestion="onSelectProperty"
          />
        </bg-field>

        <bg-grid>
          <bg-grid-item :col="6" class="mb-0">
            <bg-field
              class="mb-0"
              label="Kota"
              data-testid="property-city"
              :description="display.city"
            />
          </bg-grid-item>
          <bg-grid-item :col="6" class="mb-0">
            <bg-field
              class="mb-0"
              label="Sisa Kontrak Kerja Sama"
              data-testid="property-remaining-contract"
              :description="display.remainingContract"
            />
          </bg-grid-item>
        </bg-grid>
      </bg-grid-item>
    </bg-grid>

    <bg-skeleton v-if="isLoading.page" width="100%" height="329px" />
    <other-transaction-expenses
      v-else
      class="mb-24"
      data-testid="expense-detail"
      :options="options"
      :preset-data="presetExpense"
      @is-all-expenses-filled="expensesValidation"
      @get-expenses="getExpenses"
    />

    <bg-grid>
      <bg-grid-item :col="6" class="mb-0">
        <bg-field
          class="mb-16"
          label="Total Pengeluaran"
          data-testid="total-expense"
          :description="formattedTotalExpense"
        />

        <bg-field
          class="flex mb-16"
          label="Lampiran"
          :message="
            attachment.isError ? attachment.errMessage : attachment.description
          "
          :error="attachment.isError"
        >
          <bg-skeleton v-if="isLoading.page" width="250px" height="250px" />
          <div v-else>
            <div v-if="attachment.url" class="preview-attachment">
              <bg-link
                :href="attachment.url"
                target="_blank"
                rel="noopener"
                class="preview-attachment__content"
                data-testid="attachment-preview"
              >
                {{ attachment.name }}
              </bg-link>
              <bg-button-icon
                icon="delete"
                size="sm"
                data-testid="soft-delete-attachment"
                @click="handleSoftDeleteAttachment"
              />
            </div>
            <bg-uploader
              v-else
              :accept="attachment.type"
              :progress="attachment.progress"
              :is-error="attachment.isError"
              :message="attachment.message"
              :box-height="attachment.isEmpty ? 250 : 60"
              :box-width="250"
              :is-progress-delete="attachment.isDeleting"
              :is-success-delete="attachment.deleted"
              data-testid="attachment-uploader"
              @upload="handleChangeFile"
              @delete="handleDeleteAttachment"
            />
          </div>
        </bg-field>

        <bg-field class="mb-16" label="No. Invoice Biaya" message="Opsional">
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />
          <bg-input
            v-else
            v-model="input.invoice_number"
            class="mb-8"
            placeholder="Masukkan invoice biaya"
            maxlength="30"
            data-testid="cost-invoice-number"
          />
        </bg-field>

        <bg-field
          class="mb-16"
          label="Tujuan Transfer Pengeluaran"
          :message="input.cash_out_id === 1 ? 'Opsional' : ''"
        >
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />
          <bg-select
            v-else
            v-model="input.vendor_id"
            placeholder="Pilih tujuan transfer pengeluaran"
            searchable
            data-testid="vendor"
            :search-placeholder="'Cari tujuan transfer'"
            :options="options.vendors"
          />
        </bg-field>
      </bg-grid-item>
    </bg-grid>

    <bg-grid>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nama Bank"
          data-testid="bank-name"
          :description="display.bank_name"
        />
      </bg-grid-item>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nomor Rekening"
          data-testid="account-number"
          :description="display.account_number"
        />
      </bg-grid-item>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nama Pemilik Rekening"
          data-testid="account-name"
          :description="display.account_name"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-button
      variant="primary"
      :disabled="!isAllFieldFilled"
      data-testid="update-expenditure"
      @click="showConfirmationModal = true"
    >
      Ubah Data
    </bg-button>

    <bg-modal
      v-model="showConfirmationModal"
      title="Yakin ingin ubah data ini?"
      description="Data yang diubah akan mengubah data sebelumnya."
      desktop-size="sm"
      :close-on-click-backdrop="false"
      :closable="false"
    >
      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            size="lg"
            :loading="isLoading.updateData"
            @click="showConfirmationModal = false"
          >
            Batal
          </bg-button>
          <bg-button
            variant="primary"
            size="lg"
            :loading="isLoading.updateData"
            @click="fetchPutExpenditure"
          >
            Ubah
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgSelect,
  BgDivider,
  BgSkeleton,
  BgSearchBar,
  BgInput,
  BgLink,
  BgButtonIcon,
  BgUploader,
  BgButton,
  BgModal,
} from 'bangul-vue';

import { endpoints } from '@admin/api/endpoints/other-transaction-expenditure.js';
import expenditureMixins from '../mixins/expenditureMixins.js';

export default {
  name: 'OwnerExpenditureEdit',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgDivider,
    BgSkeleton,
    BgSearchBar,
    BgInput,
    BgLink,
    BgButtonIcon,
    BgUploader,
    BgButton,
    BgModal,
    PageHeader: () => import('@admin_molecules/PageHeader'),
    OtherTransactionExpenses: () =>
      import('../_components/OtherTransactionExpenses'),
  },

  mixins: [expenditureMixins],

  data() {
    return {
      presetExpense: [],
    };
  },

  computed: {
    propertyId() {
      return this.$route.params.property_id;
    },
  },

  async created() {
    this.isLoading.page = true;
    this.fetchGetPropertyExpensesOptions();
    await this.fetchGetPropertyExpensesVendors();
    this.fetchGetDetailPropertyExpenses();
    await this.fetchGetProperties();
    this.isLoading.page = false;
  },

  methods: {
    async fetchGetDetailPropertyExpenses() {
      this.isLoading.options = true;
      const response = await endpoints.getDetailPropertyExpenses(
        this.propertyId
      );

      if (response) {
        /* eslint-disable no-unused-vars */
        const {
          debug,
          attachment,
          property,
          expense_detail,
          ...rest
        } = response;

        const remappedExpenses = expense_detail.map(item => ({
          ...item,
          is_stock_available: item.is_stock_available ? '1' : '0',
        }));

        this.input = {
          ...rest,
          expense_detail: remappedExpenses,
          property_id: property.id,
          attachment_id: attachment ? attachment.id : null,
        };
        this.presetExpense = remappedExpenses;
        this.display = {
          ...this.display,
          property: property.name,
          city: property.city,
          remainingContract: `${property.remaining_contract_duration} Bulan`,
        };
        this.attachment.oldValue = attachment?.id;
        this.attachment.name = attachment?.filename;
        this.attachment.url = attachment?.url;
      }
      this.isLoading.options = false;
    },

    async fetchPutExpenditure() {
      this.isLoading.updateData = true;

      const response = await endpoints.putExpenditure(
        this.propertyId,
        this.input
      );

      if (response) {
        this.$router.push({ name: 'other-transaction' });
        this.$toast.show('Data berhasil diubah');
      }

      // Delete old file if new one has been uploaded
      if (this.attachment.hasSoftDeleted) {
        await this.fetchDeleteAttachment(this.attachment.oldValue);
      }

      this.isLoading.updateData = false;
    },

    handleSoftDeleteAttachment() {
      this.attachment.url = null;
      this.attachment.hasSoftDeleted = true;
      this.input.attachment_id = null;
    },

    handleBackButton() {
      // Delete latest uploaded file
      // If back button has been clicked and hasSoftDelete is true
      if (
        this.attachment.hasSoftDeleted &&
        this.input.attachment_id !== this.attachment.oldValue
      ) {
        this.fetchDeleteAttachment(this.input.attachment_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-other-transaction-add {
  padding: 0 16px;
}
::v-deep .bg-c-uploader {
  > div > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bg-c-progress-bar__indicator {
    background-color: #a4237e;
  }

  &__preview-content-action-reupload,
  &__preview-content-action-cancel {
    display: none;
  }

  &__error-message {
    color: black;
    font-weight: 400;
  }
}
.preview-attachment {
  display: flex;
  max-width: 300px;
  place-items: center;

  &__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}
</style>
